<template>
  <v-container id="activity">
    <v-row :no-gutters="$vuetify.breakpoint.smAndDown">

      <v-col cols="12" md="9">
        <!-- Stats Cards Row -->
        <v-row class="mb-4">
          <v-col cols="12" md="6">
            <v-card :loading="loading" :disabled="loading" class="rounded-lg elevation-3 stats-card">
              <v-card-text class="text-center py-6">
                <div class="text-h3 font-weight-bold primary--text mb-2">
                  {{ workouts.length }}
                </div>
                <div class="d-flex align-center justify-center">
                  <v-icon left color="primary" size="24">mdi-weight-lifter</v-icon>
                  <div class="text-subtitle-1 grey--text text--darken-1">
                    Total de Entrenamientos
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card :loading="loading" :disabled="loading" class="rounded-lg elevation-3 stats-card">
              <v-card-text class="text-center py-6">
                <div class="text-h3 font-weight-bold amber--text text--darken-2 mb-2">
                  {{ averageRating.toFixed(1) }}
                </div>
                <div class="d-flex align-center justify-center">
                  <v-icon left color="amber darken-2" size="24">mdi-star</v-icon>
                  <div class="text-subtitle-1 grey--text text--darken-1">
                    Calificación Promedio
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- Activity Card -->
        <v-card class="rounded-lg elevation-3 mb-4">
          <v-toolbar color="transparent" flat class="pl-4">
            <v-toolbar-title class="text-h6 font-weight-bold">Actividad</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" text class="text-capitalize" v-on="on" elevation="0">
                  <v-icon left>mdi-calendar</v-icon>
                  {{ selectedPeriod }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in periodOptions" :key="index" @click="selectedPeriod = item">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-divider v-if="selectedPeriod === 'Periodo personalizado'" />
          <v-toolbar flat v-if="selectedPeriod === 'Periodo personalizado'">
            <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
              max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-model="dateRangeText" placeholder="Selecciona un rango de fechas"
                  prepend-inner-icon="mdi-calendar" readonly dense hide-details filled rounded single-line
                  class="rounded-lg" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateRange" range @change="onDateRangeChange" locale="es"></v-date-picker>
            </v-menu>
          </v-toolbar>
          <v-divider />
          <v-card-text class="activityCard pa-0"
            v-bind:class="{ 'small': $vuetify.breakpoint.smAndDown, 'pa-4': !loading && workouts.length == 0 }">
            <!-- Add search input -->

            <v-data-table :headers="workoutHeaders" :items="workouts" :items-per-page="10" :loading="loading"
              class="elevation-0" :footer-props="{
                'items-per-page-options': [10, 25, 50],
                'items-per-page-text': 'Filas por página:'
              }">
              <!-- Loading slot -->
              <template v-slot:loading>
                <v-skeleton-loader type="table-row-divider@10"></v-skeleton-loader>
              </template>

              <!-- User column -->
              <template v-slot:[`item.user`]="{ item }">
                <div class="d-flex align-center">
                  <v-avatar size="33" class="mr-2">
                    <UserImage :user="{ id: item.user_id }" small />
                  </v-avatar>
                  <div>
                    <div class="font-weight-medium">{{ item.user_name }}</div>
                    <div class="caption grey--text">
                      {{ formatDate(item.date) }}
                      <template v-if="item.late">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="amber" small class="mr-1" v-bind="attrs" v-on="on">
                              mdi-clock-outline
                            </v-icon>
                          </template>
                          <span>Completado {{ getDaysLate(item.late, item.date) }} {{
                            getDaysLate(item.late, item.date) === 1 ? 'día' : 'días' }} tarde</span>
                        </v-tooltip>
                      </template>
                    </div>
                  </div>
                </div>
              </template>

              <!-- Plan column -->
              <template v-slot:[`item.plan_name`]="{ item }">
                <v-chip x-small label v-if="item.plan_name">
                  {{ item.plan_name }}
                </v-chip>
              </template>

              <!-- Week column -->
              <template v-slot:[`item.currentWeek`]="{ item }">
                <v-chip small v-if="item.currentWeek !== null">
                  {{ item.currentWeek + 1 }}
                </v-chip>
              </template>

              <!-- Rating column -->
              <template v-slot:[`item.rating`]="{ item }">
                <div class="d-flex align-center" v-if="item.rating">
                  <v-icon color="amber" small class="mr-1">
                    {{ item.rating >= 5 ? 'mdi-star' : 'mdi-star-half-full' }}
                  </v-icon>
                  <span>{{ item.rating }}</span>
                </div>
              </template>

              <!-- Workouts completed column -->
              <template v-slot:[`item.workoutsCompleted`]="{ item }">
                <v-chip small v-if="item.workoutsCompleted">
                  <v-icon left x-small>mdi-dumbbell</v-icon>
                  {{ item.workoutsCompleted }}
                </v-chip>
              </template>

              <!-- Exercises column -->
              <template v-slot:[`item.exercises`]="{ item }">
                <v-btn small text color="primary" @click="showExercises(item)" v-if="item.day_exercises">
                  Ver ejercicios
                </v-btn>
              </template>

              <!-- Comment column -->
              <template v-slot:[`item.comment`]="{ item }">
                <span v-if="item.comment" class="caption">{{ item.comment }}</span>
              </template>
            </v-data-table>

            <v-scroll-x-transition hide-on-leave>
              <v-alert v-if="!loading && workouts.length == 0" type="info" text class="ma-4 mb-4">
                No hay actividad para mostrar.
              </v-alert>
            </v-scroll-x-transition> </v-card-text>


        </v-card>

      </v-col>



      <v-col cols="12" md="3">
        <v-card :disabled="loading" :loading="loading" class="mb-4 elevation-3 rounded-lg">
          <v-card-text class="text-center">
            <div class="text-h3 font-weight-bold">
              {{ users.length }}
            </div>
            <div class="text-subtitle-1 grey--text">
              Total de Usuarios
            </div>
          </v-card-text>
          <v-card-text class="d-flex justify-space-around">
            <div class="text-center">
              <div class="text-h4 success--text">{{ users.length - inactiveUsers.length }}</div>
              <div class="caption">Activos</div>
            </div>
            <v-divider vertical></v-divider>
            <div class="text-center">
              <div class="text-h4 error--text">{{ inactiveUsers.length }}</div>
              <div class="caption">Inactivos</div>
            </div>
          </v-card-text>
          <!--   <v-divider/>
            <v-card-text class="">
               <apexchart class="rounded overflow-hidden"
                  type="pie"
                  height="250"
                  :options="activeInactiveChartOptions"
                  :series="activeInactiveChartSeries"
                ></apexchart>
            </v-card-text> -->
        </v-card>
        <!-- Inactive Users Table -->
        <v-card class="mt-4 elevation-3 rounded-lg" v-show="inactiveUsers.length > 0">
          <v-toolbar flat color="transparent">
            <v-toolbar-title class="text-h6">Inactivos</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-data-table :headers="inactiveUsersHeaders" :items="inactiveUsers" class="elevation-0" :loading="loading"
              hide-default-header>
              <template #[`item.name`]="{ item }">
                <v-avatar size="32" class="mr-2">
                  <UserImage :user="{ id: item.id }" small />
                </v-avatar>
                {{ item.name }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  getDocs,
} from 'firebase/firestore';
import UserImage from '@/components/profile/UserImage.vue';
import Simple from '@/components/planifications/view/Simple.vue';
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    UserImage,
    Simple,
    apexchart: VueApexCharts,
  },
  name: 'Actividad',
  data() {
    return {
      averageRating: 0,
      loading: true,
      selectedPeriod: 'Ultima semana',
      periodOptions: ['Ultima semana', 'Ultimo mes', 'Periodo personalizado'],
      menu: false,
      dateRange: [],
      dateRangeText: '',
      workouts: [],
      users: [],
      inactiveUsers: [],
      inactiveUsersHeaders: [
        { text: 'Nombre', value: 'name' },
      ],
      unsubscribes: [],
      workoutsMap: {},
      // Chart data and options
      activeInactiveChartOptions: {
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
        chart: {
          type: 'pie',
          toolbar: {
            show: false
          }
        },
        labels: ['Activos', 'Inactivos'],
        xaxis: {
          categories: ['Usuarios'],
        },
        colors: ['#4caf50', '#ff5252'],
      },
      activeInactiveChartSeries: [
        {
          name: 'Usuarios Activos',
          data: [0],
        },
        {
          name: 'Usuarios Inactivos',
          data: [0],
        },
      ],
      trainingsOverTimeChartOptions: {
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
        chart: {
          type: 'line',
        },
        xaxis: {
          categories: [],
        },
      },
      trainingsOverTimeChartSeries: [
        {
          name: 'Número de Entrenamientos',
          data: [],
        },
      ],
      averageRatingOverTimeChartOptions: {
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
        chart: {
          type: 'line',
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          min: 0,
          max: 5,
        },
      },
      averageRatingOverTimeChartSeries: [
        {
          name: 'Calificación Promedio',
          data: [],
        },
      ],
      workoutHeaders: [
        { text: 'Usuario', value: 'user', sortable: false },
        { text: 'Plan', value: 'plan_name', sortable: false },
        { text: 'Semana', value: 'currentWeek', align: 'center' },
        { text: 'Completados', value: 'workoutsCompleted', align: 'center' },
        { text: 'Calificación', value: 'rating', align: 'center' },
        { text: 'Comentario', value: 'comment', align: 'start' },
      ],
      selectedWorkout: null,
      exercisesDialog: false,
      search: '',
    };
  },
  watch: {
    selectedPeriod() {
      if (this.selectedPeriod !== 'Periodo personalizado') {
        this.fetchWorkouts();
      }
    },
    dateRange: {
      handler(newVal) {
        if (newVal.length === 2) {
          this.fetchWorkouts();
        }
      },
      deep: true,
    },
  },
  methods: {
    onPeriodChange() {
      if (this.selectedPeriod !== 'Periodo personalizado') {
        this.menu = false;
      } else {
        this.menu = true;
      }
    },
    onDateRangeChange() {
      this.menu = false;
    },
    formatDate(date) {
      const workoutDate = moment(date.toDate());
      const now = moment();



      // For older workouts, show date and time
      return workoutDate.locale('es').format('D MMM HH:mm:ss Y'); // e.g., "27 feb 14:50"
    },
    async fetchUsers() {
      const db = getFirestore();
      const usersCollection = collection(db, 'users');
      const usersQuery = query(usersCollection, where('type', 'in', ['usuario', 'canje', 'representante', 'opengym', 'personalizado', 'gratis', 'paselibre', 'online']), where('disabled', '==', false));

      try {
        const querySnapshot = await getDocs(usersQuery);
        let users = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().displayName, // Adjust if your field is different
          // ... other user fields
          type: doc.data().type,
        }));

        //remove type superuser
        this.users = users.filter(user => user.type !== 'superuser');
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    async fetchWorkouts() {
      // Clean up previous listeners
      this.unsubscribes.forEach((unsubscribe) => unsubscribe());
      this.unsubscribes = [];
      this.workoutsMap = {};
      this.workouts = [];
      this.loading = true;

      const db = getFirestore();
      let startDate, endDate;

      if (this.selectedPeriod === 'Ultima semana') {
        startDate = moment().subtract(7, 'days').startOf('day').toDate();
        endDate = new Date();
      } else if (this.selectedPeriod === 'Ultimo mes') {
        startDate = moment().subtract(1, 'months').startOf('day').toDate();
        endDate = new Date();
      } else if (this.selectedPeriod === 'Periodo personalizado' && this.dateRange.length === 2) {
        startDate = moment(this.dateRange[0]).startOf('day').toDate();
        endDate = moment(this.dateRange[1]).endOf('day').toDate();
      } else {
        startDate = null;
        endDate = null;
      }

      try {
        // First fetch users to have their names ready
        await this.fetchUsers();
        const userMap = this.users.reduce((acc, user) => {
          acc[user.id] = user.name;
          return acc;
        }, {});

        const workoutsCollection = collection(db, 'workouts');
        let workoutsQuery;

        if (startDate && endDate) {
          workoutsQuery = query(
            workoutsCollection,
            where('date', '>=', startDate),
            where('date', '<=', endDate),
            orderBy('date', 'desc')
          );
        } else {
          workoutsQuery = query(workoutsCollection, orderBy('date', 'desc'));
        }

        const unsubscribe = onSnapshot(
          workoutsQuery,
          async (snapshot) => {
            // Collect all unique plan IDs
            const planIds = new Set();

            snapshot.docChanges().forEach((change) => {
              const workoutData = change.doc.data();
              if (workoutData.mainWorkout?.plan_id) {
                planIds.add(workoutData.mainWorkout.plan_id);
              }
            });

            // Fetch all plans at once  
            const plansMap = {};
            if (planIds.size > 0) {
              const planificationsCollection = collection(db, 'planifications');
              const planQuery = query(
                planificationsCollection,
                where('__name__', 'in', Array.from(planIds))
              );

              const planSnapshot = await getDocs(planQuery);
              planSnapshot.forEach((doc) => {
                plansMap[doc.id] = doc.data();
              });
            }

            // Process workouts with plan data
            snapshot.docChanges().forEach((change) => {
              const workoutId = change.doc.id;
              const workoutData = change.doc.data();

              if (change.type === 'added' || change.type === 'modified') {
                const userName = userMap[workoutData.user_id] || 'Usuario Desconocido';

                // Add plan information if available
                let planInfo = {};
                let currentWeek = null;

                if (workoutData.mainWorkout?.plan_id) {
                  const plan = plansMap[workoutData.mainWorkout.plan_id];
                  if (plan) {
                    planInfo = {
                      plan_name: plan.name || 'Plan sin nombre',
                    };

                    // Calculate current week if start date exists
                    if (workoutData.mainWorkout.start) {
                      currentWeek = moment(workoutData.date.toDate()).diff(moment(workoutData.mainWorkout.start), 'weeks');
                    }
                  }
                }

                this.$set(this.workoutsMap, workoutId, {
                  id: workoutId,
                  ...workoutData,
                  user_name: userName,
                  plan_name: planInfo.plan_name || null,
                  currentWeek: currentWeek,
                  workoutsCompleted: workoutData.workoutsCompleted || 0
                });
              } else if (change.type === 'removed') {
                this.$delete(this.workoutsMap, workoutId);
              }
            });

            // Update workouts array and continue with existing logic
            this.workouts = Object.values(this.workoutsMap);
            this.workouts.sort((a, b) => b.date.toMillis() - a.date.toMillis());

            if (this.workouts.length > 0) {
              // Filter out workouts with rating 0 before calculating average
              const validRatings = this.workouts.filter(workout => workout.rating > 0);
              this.averageRating = validRatings.length > 0
                ? validRatings.reduce((acc, workout) => acc + workout.rating, 0) / validRatings.length
                : 0;
            } else {
              this.averageRating = 0;
            }

            // Determine inactive users using the existing user list
            const activeUserIds = new Set(this.workouts.map((workout) => workout.user_id));
            this.inactiveUsers = this.users.filter((user) => !activeUserIds.has(user.id));

            this.loading = false;

            // Update charts
            this.updateCharts();
          },
          (error) => {
            console.error('Error listening to workouts:', error);
            this.loading = false;
          }
        );

        this.unsubscribes.push(unsubscribe);
      } catch (error) {
        console.error('Error fetching workouts:', error);
        this.loading = false;
      }
    },
    updateCharts() {
      // Update Active and Inactive Users Chart
      const totalUsers = this.users.length;
      const inactiveUsersCount = this.inactiveUsers.length;
      const activeUsersCount = totalUsers - inactiveUsersCount;
      this.activeInactiveChartSeries = [0, 0]  // Changed from array of objects to array of values
      this.activeInactiveChartSeries = [activeUsersCount, inactiveUsersCount];
      /*   this.activeInactiveChartSeries = [
          {
            name: 'Usuarios Activos',
            data: [activeUsersCount],
          },
          {
            name: 'Usuarios Inactivos',
            data: [inactiveUsersCount],
          },
        ]; */

      // Update Number of Trainings Over Time Chart
      const workoutsByDate = {};
      const ratingsByDate = {};

      this.workouts.forEach((workout) => {
        const dateStr = moment(workout.date.toDate()).format('YYYY-MM-DD');
        if (!workoutsByDate[dateStr]) {
          workoutsByDate[dateStr] = 0;
          ratingsByDate[dateStr] = [];
        }
        workoutsByDate[dateStr]++;
        if (workout.rating) {
          ratingsByDate[dateStr].push(workout.rating);
        }
      });

      const dates = Object.keys(workoutsByDate).sort();

      this.trainingsOverTimeChartOptions.xaxis.categories = dates;
      this.trainingsOverTimeChartSeries = [
        {
          name: 'Número de Entrenamientos',
          data: dates.map((date) => workoutsByDate[date]),
        },
      ];

      // Update Average Workout Rating Over Time Chart
      this.averageRatingOverTimeChartOptions.xaxis.categories = dates;
      this.averageRatingOverTimeChartSeries = [
        {
          name: 'Calificación Promedio',
          data: dates.map((date) => {
            const ratings = ratingsByDate[date];
            const average =
              ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length || 0;
            return parseFloat(average.toFixed(2));
          }),
        },
      ];
    },
    getDaysLate(lateTimestamp, completedTimestamp) {
      const lateDate = moment(lateTimestamp.toDate());
      const completedDate = moment(completedTimestamp.toDate());
      return lateDate.diff(completedDate, 'days');
    },
    showExercises(workout) {
      this.selectedWorkout = workout;
      this.$dialog.show({
        component: Simple,
        props: {
          exercises: workout.day_exercises
        },
        title: `Ejercicios - ${workout.user_name}`,
        width: '600px',
      });
    },
  },
  mounted() {
    this.fetchWorkouts();
  },
  beforeDestroy() {
    // Clean up listeners
    this.unsubscribes.forEach((unsubscribe) => unsubscribe());
    this.unsubscribes = [];
  },
};
</script>

<style scoped>
.stats-card {
  transition: transform 0.2s;
}

.stats-card:hover {
  transform: translateY(-2px);
}

.workout-panel {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.workout-panel:last-child {
  border-bottom: none;
}

.activityCard.small {
  max-height: calc(100vh - 170px);
  overflow-y: auto;
  scrollbar-width: thin;
}

.activityCard.small::-webkit-scrollbar {
  width: 6px;
}

.activityCard.small::-webkit-scrollbar-track {
  background: transparent;
}

.activityCard.small::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-data-table ::v-deep .v-data-table__wrapper {
  overflow-x: auto;
}
</style>

<style>
#activity .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>